// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA-0wOn_YFO_kVd0DFb8tPiszDNzY3uC_A",
  authDomain: "tuppel-app.firebaseapp.com",
  projectId: "tuppel-app",
  storageBucket: "tuppel-app.appspot.com",
  messagingSenderId: "16935308533",
  appId: "1:16935308533:web:e490ba5bd1c783304f49bc"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);