// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Auth from './Auth';
import App from './App';
import { auth } from './firebase'; // Your Firebase setup
import './index.css'; // Tailwind CSS

// Pre-render logic: check user authentication state
auth.onAuthStateChanged(async (user) => {
  if (user) {
    if(user.email=="admin@yenbek.com"){
    renderApp(true); // Pass `true` to indicate the user is authenticated
    }
  } else {
    renderApp(false); // Pass `false` if the user is not authenticated
  }
});

// Function to render the app based on authentication state
const renderApp = (isAuthenticated) => {
 ReactDOM.render(
    <React.StrictMode>
      <Router>
        <Routes>
          {isAuthenticated ? (
            <Route path="/home" element={<App />} />
          ) : (
            <Route path="/" element={<Auth />} />
          )}
          {/* Redirect any other route to the correct one */}
          <Route path="*" element={<Navigate to={isAuthenticated ? "/home" : "/"} />} />
        </Routes>
      </Router>
    </React.StrictMode>,
    document.getElementById('root')
  );
};