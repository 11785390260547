import React, { useEffect, useState } from 'react';
import axios from 'axios';


function App() {

  const [stats, setStats] = useState({
    activeWeek: 0,
    activeMonth: 0,
    totalUsers: 0,
    payingUsers: 0,
    revenueMonth: 0,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch stats when the component mounts
  useEffect(() => {
    const fetchStats = async () => {
      try {

        const response = await axios.post('https://us-central1-tuppel-app.cloudfunctions.net/adminPayload', {"pass": "janaqazaqstan2024"});
        const { activeWeek, activeMonth, totalUsers, payingUsers } = response.data;
        const revenueMonth = payingUsers*2.99*0.7;

        // Set the data into state
        setStats({
          activeWeek,
          activeMonth,
          totalUsers,
          payingUsers,
          revenueMonth,
        });  

        setLoading(false);
      } catch (err) {
        setError("Failed to fetch statistics");
        setLoading(false);
      }
    };

    fetchStats();

  }, []);

  if (loading) {
    return <div className="flex items-center justify-center min-h-screen bg-gray-100">Loading...</div>;
  }

  if (error) {
    return <div className="flex items-center justify-center min-h-screen bg-red-100">{error}</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <h1 className="text-3xl font-bold text-gray-800 mb-8">Yenbek Admin Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 w-full max-w-4xl">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-gray-700">Active Users (Week)</h2>
          <p className="text-3xl font-bold text-indigo-600">{stats.activeWeek}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-gray-700">Active Users (Month)</h2>
          <p className="text-3xl font-bold text-indigo-600">{stats.activeMonth}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-gray-700">Total Users</h2>
          <p className="text-3xl font-bold text-indigo-600">{stats.totalUsers}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-xl font-semibold text-gray-700">Paid Users (Month)</h2>
          <p className="text-3xl font-bold text-indigo-600">{stats.payingUsers}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg md:col-span-2 lg:col-span-4">
          <h2 className="text-xl font-semibold text-gray-700">Revenue (This Month)</h2>
          <p className="text-3xl font-bold text-green-600">${stats.revenueMonth}</p>
        </div>
      </div>
    </div>
  );
}

export default App;
